







































































































import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'

import {documentsNamespace} from '@store/documents'
import {userNamespace} from '@store/user'
import {DocumentsActionTypes} from '@store/documents/Types'
import {IDocumentTypes, IDocument, IDocumentType} from '@store/documents/Interface'

import Documents from '@/mixins/Documents'
import http from '@/http'

@Component({
  components: {
    'v-ecp-icon': () => import('@/views/library/components/EcpIcon.vue'),
  }
})
export default class Document extends mixins(Documents) {
  @documentsNamespace.State('documentsAll')
  private documents!: IDocumentType

  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @documentsNamespace.State('documentTypes')
  private categoryOptions!: IDocumentTypes[]

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_ALL)
  private fetchDocumentsAll!: (params) => void

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENT_READ)
  private documentRead!: ({documentId, documentIds}) => Promise<void>

  @documentsNamespace.Action(DocumentsActionTypes.A_DELETE_DOCUMENT)
  private deleteDocument!: (documentId: number) => Promise<void>

  @Prop({default: ''}) private doc
  @Prop({default: ''}) private filterParams

  private showFormComment: boolean = false
  private loadingFormComment: boolean = false
  private selectedDocumentId: number | null = null



  get selectedDocument() {
    return this.documents.documentList.find(doc => doc.id === this.selectedDocumentId)
  }

  private documentCategory(category) {
    const cat: IDocumentTypes | null = this.categoryOptions.find(item => item.id === category) || null
    if (cat) {
      return cat.name
    }
    return 'Нет названия'
  }

  private handleReadDocument(document: IDocument) {
    if (document.isNew) {
      this.documentRead({documentId: document.id, documentIds: []})
    }
  }

  private async download(doc) {
    this.$emit('processingDocument', true)
    await this.downloadDocumentById(doc.id)
    this.$emit('processingDocument', false)
  }

  private commentDocument(id) {
    this.selectedDocumentId = id
    this.showFormComment = true
  }

  private closeFormComment() {
    this.selectedDocumentId = null
    this.showFormComment = false
  }

  private addComment(document) {
    this.loadingFormComment = true

    http
      .put(`/documents/${this.selectedDocumentId}`, {comment: document.comment})
      .then(() => {
        this.fetchDocumentsAll({projectId: this.selectedProject, filter: this.filterParams})
        this.selectedDocumentId = null
        this.showFormComment = false
        this.$successNotify();
      })
      .catch(error => {
        this.$errorNotify(error?.response?.data?.message);
      })
      .finally(() => {
        this.loadingFormComment = false
      })
  }

  private handleDeleteDocument(docId: number) {
    this.$confirm('Вы действительно хотите удалить документ?', 'Внимание!', {
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      type: 'warning',
    }).then(() => {
      this.$emit('processingDocument', true)

      this.deleteDocument(docId)
        .then(() => {
          this.fetchDocumentsAll({projectId: this.selectedProject, filter: this.filterParams})
          this.$successNotify();
        })
        .catch(error => {
          this.$errorNotify(error?.response?.data?.message);
        })
        .finally(() => {
          this.$emit('processingDocument', false)
        })
    })
  }
}
